<!-- FIXME: Replace with @morev/vue-transitions when node is upgraded -->
<template>
  <Transition name="slide-x" type="animation">
    <slot />
  </Transition>
</template>

<style scoped>
.slide-x-enter-active {
  animation: slide-x-in 0.5s;
  transform-origin: left;
}
.slide-x-leave-active {
  animation: slide-x-in 0.5s reverse;
  transform-origin: left;
}
@keyframes slide-x-in {
  0% {
    transform: scale(0, 1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
